import { MenuEntry } from '@macist-m/robinia-uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://pancakeswap.finance/swap?outputCurrency=0xAfAEEe58a58867c73245397C0F768FF041D32d70',
      },
      {
        label: 'Liquidity',
        href: 'https://pancakeswap.finance/add/BNB/0xAfAEEe58a58867c73245397C0F768FF041D32d70',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  {
    label: 'IFO',
    icon: 'IfoIcon',
    href: '/ifo',
  },
  // {
  //   label: 'Delegate',
  //   icon: 'TicketIcon',
  //   href: '/delegatefarm',
  // },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },
  {
     label: 'Info',
     icon: 'InfoIcon',
     items: [
       {
          label: 'Docs',
          href: 'https://blokfield.gitbook.io/robinia/',
       },
      {
      label: 'Audit',
      href: 'https://github.com/TechRate/Smart-Contract-Audits/blob/main/September/RobiniaSwap.pdf',
      },
      {
      label: 'Price Chart',
      href: 'https://poocoin.app/tokens/0xafaeee58a58867c73245397c0f768ff041d32d70',
      },
      //  {
      //    label: 'CoinGecko',
      //    href: 'https://www.coingecko.com/en/coins/goose-finance',
      //  },
      //  {
      //    label: 'CoinMarketCap',
      //    href: 'https://coinmarketcap.com/currencies/goose-finance/',
      // },
      //  {
      //    label: 'AstroTools',
      //    href: 'https://app.astrotools.io/pancake-pair-explorer/0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      //  },
     ],
   },
    {
    label: 'Bridge',
    icon: 'HandshakeIcon',
    href:'https://bridge.robiniaswap.com/'
    },
    {
    label: 'Bid Bot',
    icon: 'TicketIcon',
    href:'https://bidbot.robiniaswap.com/'
    }
  //     {
  //       label: 'Github',
  //       href: 'https://github.com/goosedefi/',
  //     },
  //     {
  //       label: 'Docs',
  //       href: 'https://goosedefi.gitbook.io/goose-finance/',
  //     },
  //     {
  //       label: 'Blog',
  //       href: 'https://goosefinance.medium.com/',
  //     },
  //   ],
  // },
  // {
  //   label: 'Partnerships/IFO',
  //   icon: 'GooseIcon',
  //   href: 'https://docs.google.com/forms/d/e/1FAIpQLSe7ycrw8Dq4C5Vjc9WNlRtTxEhFDB1Ny6jlAByZ2Y6qBo7SKg/viewform?usp=sf_link',
  // },
  // {
  //   label: 'Audit by Hacken',
  //   icon: 'AuditIcon',
  //   href: 'https://www.goosedefi.com/files/hackenAudit.pdf',
  // },
  // {
  //   label: 'Audit by CertiK',
  //   icon: 'AuditIcon',
  //   href: 'https://certik.org/projects/goose-finance',
  // },
]

export default config
