export default {
  pud: {
    70: '0xbe8d16084841875a1f398e6c3ec00bbfcbfa571b',
    97: '',
  },
  rbs: {
    70: '0xC727e4938E86479fC8440aAB10Be3D139110A4E8',
    97: '',
  },
  usdt : {
    70:'0xd16babe52980554520f6da505df4d1b124c815a7'
  },
  whoo: {
    70:'0x3eff9d389d13d6352bfb498bcf616ef9b1beac87'
  },

  masterChef: {
    70: '0x9675bb0e0F65598eD9722D39eF7C9E3052cB60B1',
    97: '',
  },
  
 
  lottery: {
    70: '',
    97: '',
  },
  lotteryNFT: {
    70: '',
    97: '',
  },
  mulltiCall: {
    70: '0x03fF6895aB1Fdff998665E34368B69a032F50578',
    97: '',
  },
  
  // delegate farm kontratları
  delegate : {
    70 : '0xaf0400156c48672c5eec011114209fe05e3e7c28',
    97 : ''
  }
}
